<template>
  <div class="trasaction-result">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Transaction {{ $route.query.result }}</h3>
            </div>
            <div class="card-body min-vh">
              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">
                  <div>
                    <el-result
                      v-if="$route.query.result === 'success'"
                      icon="success"
                      title="Transaction complete"
                    />
                    <el-result
                      v-else
                      icon="error"
                      :title="`Transaction ${$route.query.result}`"
                    />
                  </div>
                    <div class="text-center">
                        <el-button type="primary" icon="el-icon-arrow-left" @click="gotoApp">
                            Open A&B Plus App
                        </el-button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
    methods:{
        gotoApp(){
            const deeplink = `abplus://abfx/pis-response/${this.$route.query.result}/${this.$route.query.paymentInitiationId}`;
            window.location.href = deeplink;
        }
    },
    mounted() {
    }
}
</script>

<style>

</style>